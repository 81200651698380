// import './App.css';
import { Hero, Features } from '../components'
import { features } from '../data/school'
import logoImage from '../images/main-light@2x.png'
import bgImage from '../images/header-blue.png'

export default function Home() {

  const heroContent = {
    height: 'tall',
    image: logoImage,
    subtitle: 'Establishing the Voice of God in the Earth',
    bgImage
  }

  return (
    <>
      <Hero heroContent={heroContent} />
      <Features features={features} />
    </>
  );
}

