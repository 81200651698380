import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';

import ErrorPage from "./error-page";
import Root from './routes/root';
import Home from './routes/Home';
import School from './routes/School';
import Course from './routes/Course';
import Prophecy from './routes/Prophecy';
import Network from './routes/Network';
import Media from './routes/Media';
import Events from './routes/Events';
import Membership from './routes/Membership';

import reportWebVitals from './reportWebVitals';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      { path: "/",           element: <Home />       },
      { path: "/school",     element: <School />     },
      { path: "/course",     element: <Course />     },
      { path: "/prophecy",   element: <Prophecy />   },
      { path: "/network",    element: <Network />    },
      { path: "/media",      element: <Media />      },
      { path: "/events",     element: <Events />     },
      { path: "/network",    element: <Network />    },
      { path: "/membership", element: <Membership /> },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('pageContainer'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
