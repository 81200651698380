import { socials, footernav } from '../data/navigation'

export default function Footer() {
  return (
    <footer className="bg-sopdark">
      <div className="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
        <nav className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12" aria-label="Footer">
          {footernav.map((item) => (
            <div key={item.name} className="pb-6">
              <a href={item.href} className="uppercase text-xs leading-6 text-gray-400 hover:text-gray-300">
                {item.name}
              </a>
            </div>
          ))}
        </nav>
        <div className="mt-10 flex justify-center space-x-10">
          {socials.map((item) => (
            <a key={item.name} href={item.href} target="_blank" className="text-gray-400 hover:text-gray-100">
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </a>
          ))}
        </div>
        <p className="mt-10 text-center text-xs leading-5 text-gray-500">
          &copy; {(new Date().getFullYear())} Sons of Prophets
        </p>
      </div>
    </footer>
  )
}
