
export const features = [
  {
    name: 'School',
    description:
      'Helping emerging prophets, intercessors, and voices process their invitation from the Lord, discover their path, and confidently respond to their call to servanthood.',
    button: {
      label: 'View schools',
      link: '/school'
    }
  },
  {
    name: 'Chapters',
    description:
      'Running deep roots by connecting unique voices to a stable platform to build consistency, credibility, accountability, and good stewardship of the prophetic.',
    button: {
      label: 'Learn more',
      link: '/network'
    }
  },
  {
    name: 'Media',
    
    description:
      'Videos, podcast, books, and more, to normalize a close, supernatural walk with God, promote clarity in times of confusion and chaos, and quicken the body of Christ.',
    button: {
      label: 'View media',
      link: '/media'
    }
  },
]