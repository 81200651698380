import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'

const frequencies = [
  { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
  { value: 'annually', label: 'Annually', priceSuffix: '/year' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function School({ tiers, frequencyToggle }) {
  const [frequency, setFrequency] = useState(frequencies[0])

  const RadioFrequency = () => {
    return (
      <div className="flex justify-center">
        <RadioGroup
          value={frequency}
          onChange={setFrequency}
          className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
        >
          <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>
          {frequencies.map((option) => (
            <RadioGroup.Option
              key={option.value}
              value={option}
              className={({ checked }) =>
                classNames(
                  checked ? 'bg-indigo-600 text-white' : 'text-gray-500',
                  'cursor-pointer rounded-full px-2.5 py-1'
                )
              }
            >
              <span>{option.label}</span>
            </RadioGroup.Option>
          ))}
        </RadioGroup>
      </div>
    )
  }

  return (
    <>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          {frequencyToggle && <RadioFrequency />}
          <div className="isolate mx-auto grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {tiers.map((tier) => (
              <div
                key={tier.id}
                className={classNames(
                  tier.featured ? 'bg-gray-900 ring-gray-900' : 'ring-gray-200',
                  'shadow-xl'
                )}>
                <div className="bg-sopblue relative py-10">
                  <h1 id={tier.id} className="text-3xl text-white text-lg text-center">
                    {tier.name}
                  </h1>
                </div>
                <div className="p-8 xl:p-10">
                  <p className="mb-10 text-center items-baseline gap-x-1">
                    <span
                      className={classNames(
                        tier.featured ? 'text-white' : 'text-gray-900',
                        'text-4xl font-bold tracking-tight'
                      )}
                    >
                      {typeof tier.price === 'string' ? tier.price : tier.price[frequency.value]}
                    </span>
                    {typeof tier.price !== 'string' ? (
                      <span
                        className={classNames(
                          tier.featured ? 'text-gray-300' : 'text-gray-600',
                          'text-sm font-semibold leading-6'
                        )}
                      >
                        {frequency.priceSuffix}
                      </span>
                    ) : null}
                  </p>
                  <a
                    href={tier.href}
                    aria-describedby={tier.id}
                    className={classNames(
                      tier.featured
                        ? 'bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white'
                        : 'bg-sopblue text-white shadow-sm hover:bg-sopfair focus-visible:outline-sopfair',
                      'mt-6 block py-4 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
                    )}
                  >
                    {tier.buttonText}
                  </a>
                  <p className={classNames(tier.featured ? 'text-gray-300' : 'text-gray-600', 'mt-4 text-sm leading-6')}>
                    {tier.description}
                  </p>
                  <ul
                    role="list"
                    className={classNames(
                      tier.featured ? 'text-gray-300' : 'text-gray-600',
                      'mt-8 space-y-3 text-sm leading-6 xl:mt-10'
                    )}
                  >
                    {tier.features.map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <CheckIcon
                          className={classNames(tier.featured ? 'text-white' : 'text-indigo-600', 'h-6 w-5 flex-none')}
                          aria-hidden="true"
                        />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

