import { Hero, SchoolCard } from '../components';
import { schools } from '../data/school';
import bgImage from '../images/header-blue.png'

export default function School() {

  const heroContent = {
    header: 'Prophetic Schools',
    subtitle: 'PRE-ENROLLMENT IS OPEN | SCHOOLS BEGIN IN THE FALL',
    button: {
      link: 'https://google.com',
      text: 'Enroll now'
    },
    bgImage
  }

  return (
    <>
      <Hero heroContent={heroContent} />
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {schools.map((school) => (
              <SchoolCard school={school} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

