// import './App.css';
import { Hero } from '../components';
import bgImage from '../images/header-blue.png'

export default function School() {

  const heroContent = {
    header: 'Sons of Prophets Media',
    bgImage
    // subtitle: 'PRE-ENROLLMENT IS OPEN | SCHOOLS BEGIN IN THE FALL',
    // button: {
    //   link: 'https://google.com',
    //   text: 'Enroll now'
    // }
  }

  return (
    <>
      <Hero heroContent={heroContent} />
    </>
  );
}

