
export default function Course() {

  return (
    <>
      <iframe 
        id="course-content"
        src="https://www.sonsofprophets.co/path-player?courseid=spiritual-foundations"
        width="100%"
        height="100vh"
        frameBorder="0"
      ></iframe>
    </>
  );
}

