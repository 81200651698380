import { useState } from 'react'
import { Link } from "react-router-dom";
import { Softlights } from './'

const heights = {
  full:   'py-48 sm:py-56 lg:py-60',
  tall:   'py-40 sm:py-44 lg:py-48',
  normal: 'py-32 sm:py-40 lg:py-44',
  short:  'py-20 sm:py-24 lg:py-28'
}

export default function Hero({ heroContent }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div className="bg-sopdark">
      <div className="relative isolate overflow-hidden pt-14">

        <div className={`z-10 mx-auto max-w-2xl ${heights[heroContent.height] || heights.normal}`}>
          <div className="text-center">
            {heroContent.image && (
              <div className="w-72 m-auto">
                <img
                  src={heroContent.image}
                  alt=""
                  className="h-full w-full object-contain"
                />
              </div>
            )}
            {heroContent.header && (
              <h2 className="text-2xl sm:text-4xl tracking-tight text-white">
                {heroContent.header}
              </h2>
            )}
            {heroContent.subtitle && (
              <p className="mt-6 text-xs tracking-widest text-bold uppercase leading-8 text-gray-300">
                {heroContent.subtitle}
              </p>
            )}
            {heroContent.button && (
              <div className="mt-10 flex items-center justify-center gap-x-6">
              <Link to={heroContent.button.link}
                className="bg-white px-7 py-3 text-sm font-bold shadow-lg">
                {heroContent.button.text}
              </Link>
            </div>
            )}
          </div>
        </div>
        
        {heroContent.bgImage ? (
          <>
            <div className="overlay"></div>
            <img
              src={heroContent.bgImage}
              alt=""
              className="absolute inset-0 -z-10 h-full w-full object-cover"
            />
          </>
        ):(<Softlights />)}
      </div>
    </div>
  )
}
