
export const tiers = [
  {
    name: 'Community',
    id: 'tier-community',
    href: '#',
    price: { monthly: '$20', annually: '$12' },
    description: 'Everything necessary to get started.',
    buttonText: 'Start Membership',
    features: [
      'Storytime Sessions',
      'Prophetic Lifestyle course',
      'Culture Book & Materials',
      'Members-only Social Group',
      'SOP Member\'s Retreat',
      'Prophesy Store Discount'
    ],
    featured: false,
  },
  {
    name: 'Leaders',
    id: 'tier-leaders',
    href: '#',
    price: { monthly: '$42', annually: '$24' },
    description: 'Everything in Basic, plus essential tools for growing your business.',
    buttonText: 'Start Membership',
    features: [
      'Community Benefits',
      'Prophetic Archive',
      'Leadership Lifestyle course',
      'Leadership Meetings',
    ],
  },
  {
    name: 'Student',
    id: 'tier-student',
    href: '#',
    price: 'Tuition Fee',
    description: 'Paid enrollments include 1 year of community membership',
    buttonText: 'Enroll in a Course',
    features: [],
  },
]