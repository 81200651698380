import { Link } from "react-router-dom";

export default function Features({ features }) {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col text-center">
                <dt className="items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                  <h1 className="text-3xl">{feature.name}</h1>
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{feature.description}</p>
                  <p className="mt-6">
                    {feature.button && (
                      <Link to={feature.button.link}>
                        <button type="button"
                          className="bg-sopblue px-10 py-3.5 font-bold text-white shadow-sm hover:bg-sopfair">
                          {feature.button.label}
                        </button>
                      </Link>
                    )}
                  </p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
