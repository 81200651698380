import { Outlet } from "react-router-dom";
import { Navbar, Footer, Newsletter } from '../components';

function App() {
  return (
    <>
      <Navbar />
      <Outlet />
      <Newsletter />
      <Footer />
    </>
  );
}

export default App;
