// import './App.css';
import { Hero, Features } from '../components';
import { features } from '../data/prophecy'
import bgImage from '../images/bg/prophetic-eagle.jpg'

export default function School() {

  const heroContent = {
    header: 'Prophesy, son of man',
    bgImage
    // subtitle: 'PRE-ENROLLMENT IS OPEN | SCHOOLS BEGIN IN THE FALL',
    // button: {
    //   link: 'https://google.com',
    //   text: 'Enroll now'
    // }
  }

  return (
    <>
      <Hero heroContent={heroContent} />
      <Features features={features} />
    </>
  );
}

