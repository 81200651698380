import { useEffect, useState } from 'react'
import { Hero } from '../components';
import { getEventsList } from '../services/GoogleCalendarAPI'
import Moment from 'react-moment';
import 'moment-timezone';


export default function Events() {
  const [eventList, setEventList] = useState([])

  const heroContent = {
    header: 'Upcoming Events',
  }

  useEffect(() => {
    const fetchData = async () => {
      const data = await getEventsList('events')
  
      setEventList(data);
    }

    fetchData()
      .catch(console.error);
  }, [])

  return (
    <>
      <Hero heroContent={heroContent} />
      <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-4xl">
          <div className="space-y-20 lg:space-y-20">
            {eventList.map((event) => (
              <article key={event.id} className="relative isolate flex flex-col gap-8 lg:flex-row">
                <div className="relative aspect-[16/9] sm:aspect-[2/1] lg:aspect-square lg:w-64 lg:shrink-0">
                  {event.attachments[0] ? (
                    <img
                      src={`https://drive.google.com/uc?id=${event.attachments[0].fileId}`}
                      alt=""
                      className="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover"
                    />
                  ):(
                    <img
                      src={null}
                      alt=""
                      className="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover"
                    />
                  )}
                  <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                </div>
                <div>
                  <div className="flex items-center gap-x-4 text-xs">
                    <div className="text-gray-500">
                      <Moment>
                        {event.start.dateTime}
                      </Moment>
                      &nbsp;-&nbsp; 
                      <Moment>
                        {event.end.dateTime}
                      </Moment>
                    </div>
                    {/* <a
                      href={post.category.href}
                      className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                    >
                      {post.category.title}
                    </a> */}
                  </div>
                  <div className="group relative max-w-xl">
                    <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                      <a href={'#'}>
                        <span className="absolute inset-0" />
                        {event.summary}
                      </a>
                    </h3>
                    <p className="mt-5 text-sm leading-6 text-gray-600">{event.description}</p>
                  </div>
                  {/* <div className="mt-6 flex border-t border-gray-900/5 pt-6">
                    <div className="relative flex items-center gap-x-4">
                      <img src={post.author.imageUrl} alt="" className="h-10 w-10 rounded-full bg-gray-50" />
                      <div className="text-sm leading-6">
                        <p className="font-semibold text-gray-900">
                          <a href={post.author.href}>
                            <span className="absolute inset-0" />
                            {post.author.name}
                          </a>
                        </p>
                        <p className="text-gray-600">{post.author.role}</p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
