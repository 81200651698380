
export const features = [
  {
    name: 'School',
    description:
      'Helping emerging prophets, intercessors, and voices process their invitation from the Lord, discover their path, and confidently respond to their call to servanthood.',
    button: {
      label: 'View schools',
      link: '/school'
    }
  },
  {
    name: 'Chapters',
    description:
      'Running deep roots by connecting unique voices to a stable platform to build consistency, credibility, accountability, and good stewardship of the prophetic.',
    button: {
      label: 'Learn more',
      link: '/network'
    }
  },
  {
    name: 'Media',
    description:
      'Videos, podcast, books, and more, to normalize a close, supernatural walk with God, promote clarity in times of confusion and chaos, and quicken the body of Christ.',
    button: {
      label: 'View media',
      link: '/media'
    }
  },
]


export const schools = [
  {
    order: 6,
    title: 'School of Prophets',
    href: '/school/spiritual-foundations',
    description:
      'Learn to thrive in closeness with God',
    imageUrl: require('../images/bg/prophets.jpg')
  },
  {
    order: 4,
    title: 'School of the Prophetic',
    href: '/school/spiritual-foundations',
    description:
      'Learn to know the voice of the Lord',
    imageUrl: require('../images/bg/prophetic-eagle.jpg')
  },
  {
    order: 2,
    title: 'School of Intercession',
    href: '/school/spiritual-foundations',
    description:
      'Transcend heights and engage in effective spiritual warfare',
    imageUrl: require('../images/bg/school-intercession.jpg')
  },
  {
    order: 5,
    title: 'School of the Scribes',
    href: '/school/spiritual-foundations',
    description:
      'Learn to thrive in closeness with God',
    imageUrl: require('../images/bg/pen.jpg')
  },
  {
    order: 3,
    title: 'School of Pioneers',
    href: '/school/spiritual-foundations',
    description:
      'Create roads that etch into the future',
    imageUrl: require('../images/bg/telescope.jpg')
  },
  {
    order: 1,
    title: 'Spiritual Foundations',
    href: '/school/spiritual-foundations',
    description:
      'Supernatural perspective for eternal understanding',
    imageUrl: require('../images/bg/spiritual-foundations.jpg')
  },
]