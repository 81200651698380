const API_KEY = 'AIzaSyBRdhHYqQqbTQriQX4lMgIy7tM2tGXMr3o'

const calendars = {
  events: "c_aa183dd2711cb704a2faceba54da876fb594f19e7195945331d0d213b6169daa@group.calendar.google.com"
}

export function loadCalendarAPI(apiKey) {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/api.js";
    document.body.appendChild(script);
    script.onload = () => {
      window.gapi.load("client", () => {
        window.gapi.client.init({ apiKey: apiKey })
          .then(() => {
            window.gapi.client
              .load(
                "https://content.googleapis.com/discovery/v1/apis/calendar/v3/rest"
              )
              .then(
                () => resolve("GAPI client successfully loaded for API"),
                (err) => reject(err)
              );
          });
      });
    }
  })
}

export function getEventsList(calendar, maxResults = 100) {
  return new Promise((resolve, reject) => {
    loadCalendarAPI(API_KEY).then(() => {
      window.gapi.client.calendar.events.list({
        calendarId: calendars[calendar],
        maxResults: maxResults,
      })
      .then(({ result }) => resolve(result.items))
      .catch(error => reject(error))
    })
    .catch(error => reject(error))
  })
 
}
