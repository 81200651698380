import { Hero, Pricing } from '../components';
import { tiers } from '../data/membership'
import bgImage from '../images/bg/training.jpg'

export default function School() {

  const heroContent = {
    height: 'tall',
    header: 'SOP Membership',
    subtitle: 'Membership holders make up the global Sons of Prophets community',
    button: {
      link: 'https://google.com',
      text: 'Become a Member'
    },
    bgImage
  }

  return (
    <>
      <Hero heroContent={heroContent} />

      <Pricing tiers={tiers} frequencyToggle={false} />
    </>
  );
}

